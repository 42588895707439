@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;1,400&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.particle {
  position: absolute;
  will-change: transform;
}

.custom-textarea {
  resize: none;
  border: 2px solid rgb(76, 76, 126);
  border-radius: 0.6rem;
  background: transparent;
  font-family: inherit;
  color: white;
  font-size: 1rem;
  padding: 0.4rem;
}

.box {
  width: 60%;
  aspect-ratio: 1/1;
}

.blob-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

svg {
  width: 100%;
  aspect-ratio: 1/1;
}


.container {
  /*transform: scale(0.5);*/
  width:200px;
  height:200px;
  background-color: #ccc;
  background-color: #2b4764;
  border-radius:50%;
  border:15px solid #dde;
  border:15px solid #2b4764;
  position: relative;
  overflow: hidden;
  transition: 2s;
  box-shadow: 0px 1px 8px 4px rgb(55 57 60 / 50%);
}

.container div {
  height:50%;
  width:370px;

  /*width:230px;*/
  /*left:135px;*/

  position: absolute;
  clip-path: polygon(100% 0 , 50% 100% , 0 0 );
  transform:translateX(-50%);
  transform-origin:bottom;
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:22px;
  font-weight:bold;
  color:#fff;
  box-shadow: inset 0px -4px 20px 5px rgba(50, 50, 0, 0.4);
}

.container p {
  transform: translate(0, -30px) rotate(-90deg);
  user-select: none;
  text-shadow: 2px 2px 13px #000000a1;
}

.container .drink {
}
.container .primoChePassa {
  transform: translateX(-19%) rotate(72deg);
}
.container .chiama {
  transform: translateX(-19%) rotate(144deg);
}
.container .fotoSpeaker {
  transform: translateX(-19%) rotate(216deg);
}
.container .seguiFbn {
  transform: translateX(-19%) rotate(288deg);
}

/*.container .mobileArr {
  transform: rotate(225deg);
}
.container .modaAbb {
  transform: rotate(270deg);
}
.container .turismo {
  transform: rotate(315deg);
}*/

.arrow-wrap {
  filter: drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.6));
  position: relative;
  top: 30px;
  left: 220px;
  z-index:10;
}

.arrow {
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  color:#fff;
  clip-path: polygon(50% 10%, 0% 100%, 100% 100%);
  background: white;
  padding: 1.6rem;
  border-radius: 2rem;
}


#spin {
  position: relative;
  top:405px;
  left:325px;
  transform:translate(-50%,-50%);
  z-index:10;
  background-color: rgba(81, 135, 188, 1);
  background-color: #2b4764;
  text-transform: uppercase;
  border:8px solid #42688f;
  font-weight:bold;
  font-size:16px;
  color:#FFFFFF;
  width:80px;
  height:80px;
  border-radius:50%;
  cursor: pointer;
  outline:none;
  box-shadow: 0px 0px 14px 4px rgba(79, 86, 92, 0.7);
}

#arrow-visual {
  animation: bounce 3s ease 0s 3 normal forwards;
}

@keyframes bounce {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(-45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%,
  55%,
  75%,
  87% {
    animation-timing-function: ease-out;
    transform: translateY(0px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}
